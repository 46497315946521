@import "../../../Styles/Color.scss";
@import "../../../Styles/Variable.scss";

.main_header_section .main_header_row .main_header_menu_col .header_ul li.live-class-timer {
  display: none !important;
}

.main_header_section {
  width: 100%;
  padding: 0px 50px;

  //position: relative;
  .main_header_row {
    @extend %Flex_Space_Between;

    // position: absolute;
    // z-index: 9999;
    // margin: auto;
    // width: 90%;
    .main_header_logo_col img {
      width: 100%;
      height: 40px;
      padding: 1px;
      margin-left: 5px;
    }

    .live-class-timer {
      display: block;
      // max-width: 150px;
      background: $primary_Back;
      border-radius: 25px;
      padding: 15px 10px;
      box-shadow: $boxShadow;

      .TimeData {
        display: block;

        span {
          font-size: 13px;
          font-weight: bold;
          margin: 0px 5px;
          padding: 6px;
          background-color: #fff;
          border-radius: 5px;
          color: #1b0e4278;
        }
      }
    }

    .main_header_menu_col {
      .header_ul {
        list-style-type: none;
        padding: 0px;
        //box-shadow: $boxShadow;
        border-bottom-left-radius: 58px;
        border-bottom-right-radius: 58px;
        background: #2ebbda;
        @extend %Flex_Box;
        //@extend %Border;
        flex-wrap: wrap;
        border-top: none !important;
        padding: 6px 25px;
        text-align: center;
        border-top: none;
        border: 3px solid #2ebbda;

        li {
          display: block;
          margin: 10px 0px;

          &.disabled {
            a {
              opacity: 0.6;
              pointer-events: none;
            }
          }

          a {
            color: #444;
            text-decoration: none;
            width: 185px;
            background: $primary_White;
            border-radius: 50px;
            box-shadow: 1px 1px 19px rgb(0 0 0 / 5%);
            display: flex;
            justify-content: center;
            padding: 14px 2px;
            margin: 6px;
            transition: 0.2s;
            font-size: 15px;
            @extend %Common_15_Regular_Font;
            position: relative;
            font-weight: 600 !important;
            letter-spacing: 0.5px !important;

            &.active_nav {
              background: $Active_Button_Color !important;
              color: $primary_White !important;
              box-shadow: 0px 6px 9.7px 0.3px rgb(27 75 83 / 12%),
                inset 0 -6px 10px #0000001f;
              border: 3px solid #dbf4fc;
              @extend %Border;

              ::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                box-shadow: inset 2px 13px 22.7px -10.7px rgb(41 41 38 / 32%);
              }
            }
          }
        }
      }
    }

    .main_header_right_col {
      display: flex;

      .top_outer_arrow {
        width: 62px;
        height: 62px;
        margin: auto;
        background: url("https://cdn.jsdelivr.net/gh/meeko007/meeko@main/assets/img/logout_img.png");
        background-repeat: no-repeat;
        background-size: 62px 56px;
        position: relative;
        cursor: pointer;

        &.notification_bar {
          margin: auto;
          margin-right: 15px;

          img {
            width: 25px;
            height: 25px;
          }
        }

        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
          margin: auto;
          position: absolute;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .notification_bar {
        position: relative;

        .notification-count {
          position: absolute;
          right: 0px;
          padding: 6px 10px;
          top: -10px;
          background: red;
          border-radius: 50%;
          color: white;
          font-size: 14px;
        }
      }
    }
  }
}

.header_Relative {
  position: relative;
  width: 100%;

  .header_Absolute {
    width: 94%;
    position: absolute;
    z-index: 9999;
    margin: auto;
  }
}

.custom-popup {
  .popup_inner {
      .popup_Title {
          margin-bottom: 20px;
      }

      .live-class-end-popup {
          .live-class-end-section {
              p {
                  font-size: 20px;
              }
          }

          .live-class-end-button {
            display: flex;
              margin: 25px 20%;

              .okay-button {
                  margin-right: 3%;
                  cursor: pointer;
                  width: 185px;
                  height: 63px;
                  border: none;
                  border-radius: 14px;
                  font-family: Rubik-Medium;
                  background-color: #06add2;
                  color: #ffffff;
                  font-weight: 600;
                  font-size: 20px;
                  letter-spacing: 0.5px;
              }

              .cancel-button {
                  margin-left: 3%;
                  cursor: pointer;
                  width: 185px;
                  height: 63px;
                  border: none;
                  border-radius: 14px;
                  font-family: Rubik-Medium;
                  border-color: #06add2;
                  color: #06add2;
                  font-weight: 600;
                  font-size: 20px;
                  letter-spacing: 0.5px;
              }
          }
      }
  }
}

@media (min-width: 1601px) and (max-width: 1800px) {
  .main_header_section .main_header_row .main_header_menu_col .header_ul {
    padding: 5px 20px !important;
  }

  .main_header_section {
    .main_header_row {
      .main_header_menu_col {
        .header_ul {
          li {
            a {
              width: 165px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .main_header_section {
    .main_header_row {
      .main_header_menu_col {
        .header_ul {
          padding: 5px 20px !important;

          li {
            a {

              // width: 140px !important;
              &.active_nav {
                border-width: 2px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .main_header_section {
    padding: 0px 30px !important;

    .main_header_row {
      .main_header_menu_col {
        .header_ul {
          padding: 5px 30px !important;

          li {
            a {
              // min-width: 100px !important;
              padding: 13px 0px !important;

              &.active_nav {
                border-width: 2px !important;
              }
            }
          }
        }
      }

      .main_header_logo_col img {
        height: 30px !important;
      }

      .main_header_right_col {
        .top_outer_arrow {
          background-size: 51px 48px !important;
        }

        img {
          top: 38% !important;
          left: 43% !important;
        }
      }
    }
  }
}

@media (min-width: 1020px) and (max-width: 1200px) {
  .main_header_section {
    padding: 0px 10px !important;

    .main_header_row {
      .main_header_menu_col {
        .header_ul {
          padding: 5px 0px !important;

          li {
            margin: 5px 0px !important;

            a {
              width: 90px !important;
              padding: 8px 0px !important;

              &.active_nav {
                border-width: 2px !important;
              }
            }
          }
        }
      }

      .main_header_logo_col img {
        height: 20px !important;
      }

      .main_header_right_col {
        .top_outer_arrow {
          background-size: 51px 48px !important;
        }

        img {
          top: 38% !important;
          left: 43% !important;
        }
      }
    }
  }
}

@media (min-width: 1141px) and (max-width: 1280px) {
  .inner_side_left_box {
    .SideBar_Link_container {
      a {
        font-size: 12px !important;
      }
    }
  }
}

@media (min-width: 1026px) and (max-width: 1140px) {
  .main_header_section .main_header_row .main_header_right_col .top_outer_arrow {
    width: 51px;
    height: 48px;
  }

  .main_header_section {
    .main_header_row {
      .main_header_right_col {
        .top_outer_arrow {
          width: 51px;
          height: 48px;
        }
      }
      .main_header_menu_col {
        .header_ul {
          padding: 5px 10px !important;
          border-bottom-left-radius: 35px;
          border-bottom-right-radius: 35px;

          li {
            a {
              width: 112px !important;
              margin: 6px 3px;
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1026px) and (max-width: 1200px) {
  .header_Relative .header_Absolute {
    width: 98%;
  }
}

@media (min-width: 1100px) and (max-width: 1280px) {
  .main_header_section {
    .main_header_row {
      .main_header_menu_col {
        .header_ul {
          padding: 5px 10px !important;
          border-bottom-left-radius: 35px;
          border-bottom-right-radius: 35px;

          li {
            a {
              width: 120px !important;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1281px) and (max-width: 1440px) {
  .main_header_section {
    .main_header_row {
      .main_header_menu_col {
        .header_ul {
          padding: 5px 10px !important;

          li {
            a {
              width: 130px !important;
            }
          }
        }
      }
    }
  }

  .header_Relative .header_Absolute {
    width: 95%;
  }
}

@media (min-width: 1441px) and (max-width: 1680px) {
  .main_header_section {
    .main_header_row {
      .main_header_menu_col {
        .header_ul {
          padding: 5px 10px !important;

          li {
            a {
              width: 150px !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 667px) {
  .custom-popup .popup_inner .popup_Title {
      font-size: 32px;
  }

  .custom-popup .popup_inner .live-class-end-popup .live-class-end-section p {
      font-size: 17px;
      text-align: center;
  }

  .custom-popup .popup_inner .live-class-end-popup .live-class-end-button {
      display: flex;
      margin: 25px auto;
      justify-content: center;
  }

  .custom-popup .popup_inner .live-class-end-popup .live-class-end-button .okay-button {
      width: 125px;
  }

  .custom-popup .popup_inner .live-class-end-popup .live-class-end-button .cancel-button {
      width: 125px;
  }
}

// ---------------------------------------------------------------------
.main_header_section .main_header_row .main_header_menu_col .header_ul li a:hover {
  background: $Active_Button_Color !important;
  color: $primary_White !important;
  box-shadow: 0px 6px 9.7px 0.3px rgb(27 75 83 / 12%),
    inset 0 -6px 10px #0000001f;
  // border: 3px solid #dbf4fc;
  transition: 0.2s;
}
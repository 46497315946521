@import "../../../Styles/Color.scss";

.res-header {
  .slick-slider {
    .slick-list {
      .slick-track {
        .slick-slide {
          width: fit-content !important;
        }
      }
    }
  }
}

.Responsive_Header_Container {
  background-color: #aee8f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 5%;
  // height: 10vh !important;
  height: 66px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  .notification_bar {
    position: relative;

    .notification-count {
      position: absolute;
      right: -12px;
      padding: 3px 6px;
      top: -15px;
      background: red;
      border-radius: 50%;
      color: white;
      font-size: 12px;
    }
  }
}

.Responsive_Text {
  color: #fff;
  font-size: 20px;
}

.navbar {
  background-color: #aee8f4;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-menu {
  background-color: #aee8f4;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 999999;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 999999;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 25px;
  list-style: none;
  height: 50px;
}

.nav-text button {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  // padding: 0 16px;
  border-radius: 4px;
  border: 0px;
  cursor: pointer;
  background-color: transparent;
}

.nav-menu-items {
  width: 100%;

  .top_outer_arrow {
    padding: 8px 0px 8px 25px;
    display: flex;
    align-items: center;

    .img_bg {
      background-color: $Active_Button_Color;
      width: fit-content;
      width: 40px;
      height: 40px;
      border-radius: 50%;

      img {
        display: block;
        margin: auto;
        padding: 10px 0px;
      }
    }

    span {
      margin-left: 16px;
      color: $primary_White;
    }
  }
}

.Sidebar_Data {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ffff;
}

.navbar-toggle {
  background-color: #aee8f4;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 30px;
  padding-left: 35px;
  color: #fff;
  cursor: pointer;
}

.Title_Sidebar {
  margin-left: 16px;
}

.Slider_Responsive {
  background-color: #f1f7f8;

  .SubSliderContainer {
    .Sub_Link {
      display: flex;
      align-items: center;
      padding: 15px 10px;
      text-decoration: none;
      border-radius: 15px;
      margin: 20px 5px;
      color: #fff;
      height: 50px;
      text-align: flex-end;
      font-size: 12px;
      border: 3px solid #fff;
      box-shadow: 0px 2px 14px -3px #88888859;
    }

    .Slider_Icon_Header {
      font-size: 15px;
      margin-right: 15px;

      img {
        width: auto;
        height: 20px;
      }
    }
  }

  .live-class-option {
    display: block;

    li {
      background: #efc623 !important;
    }

    .option {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 10px;
      text-decoration: none;
      border-radius: 15px;
      margin: 20px 5px;
      color: #fff;
      height: 50px;
      text-align: flex-end;
      font-size: 12px;
      border: 3px solid #fff;
      background-color: rgb(132, 237, 124);
      box-shadow: 0px 2px 14px -3px #88888859;
      width: 140px;

      a {
        text-decoration: none;
        color: #fff;
      }

      .live-class {
        &.disabled {
          a {
            opacity: 0.6;
            pointer-events: none;
          }
        }

        a {
          text-decoration: none;
          color: #fff;
        }
      }

      &.disabled {
        a {
          opacity: 0.6;
          pointer-events: none;
        }
      }

      .live-class-timer {
        .TimeData {
          span {
            padding-right: 8px;
          }
        }
      }
    }
  }
}

.Logo_Responsive {
  width: 108px;
}

@media (min-width: 300px) and (max-width: 1140px) {
  .slick-arrow {
    display: block !important;
  }
}

@media screen and (max-width: 480px) {
  .Slider_Responsive .SubSliderContainer {
    span {
      // display: none;
    }

    .Slider_Icon_Header {
      font-size: 18px !important;
      margin-right: 5px;
      // margin-right: 0px !important;
      // margin: auto !important;
    }

    .Sub_Link {
      padding: 11px 10px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .navbar-toggle {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }
}

@media all and (min-width: 981px) and (max-width: 1024px) {
  .Slider_Responsive {
    margin-top: 0px;

    .slick-track {
      display: flex;
      justify-content: center;
      width: auto !important;
    }
  }

  .Slider_Responsive {
    .live-class-option {
      .option {
        &.disabled {
          a {
            display: flex;
            align-items: center;
          }

          i {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@media all and (min-width: 1025px) and (max-width: 1141px) {
  .Slider_Responsive {
    margin-top: 66px;

    .slick-track {
      display: flex;
      justify-content: center;
      width: auto !important;
    }
  }

  .Slider_Responsive {
    .live-class-option {
      .option {
        &.disabled {
          a {
            display: flex;
            align-items: center;
          }

          i {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@media all and (min-width: 768px) and (max-width: 980px) {
  .Slider_Responsive {
    margin-top: 0px;

    .slick-track {
      display: flex;
      justify-content: center;
      width: auto !important;
    }
  }

  .Slider_Responsive {
    .live-class-option {
      .option {
        &.disabled {
          a {
            display: flex;
            align-items: center;
          }

          i {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@media all and (min-width: 481px) and (max-width: 767px) {
  .slick-track {
    width: auto !important;
    display: block;
    justify-content: center;
  }

  .Slider_Responsive {
    margin-top: 0;

    .SubSliderContainer {
      .Slider_Icon_Header {
        margin-right: 7px !important;
        font-size: 25px !important;
      }

      .Sub_Link {
        // width: 19vw !important;
        padding: 15px 0px !important;
        justify-content: center !important;
        margin: 20px 2px !important;

        span {
          // display: none;
        }
      }
    }

    .live-class-option {
      .option {
        width: 19vw;
        margin: 20px 2px;
        justify-content: center;
        font-size: 17px;

        span {
          // display: none;
        }

        &.disabled {
          p {
            display: none !important;
          }

          a {
            display: flex;
            align-items: center;

            i {
              margin-right: 5px;
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 320px) and (max-width: 480px) {
  .Slider_Responsive {
    // margin-top: 66px;

    .SubSliderContainer {
      .Slider_Icon_Header {
        margin-right: 0px !important;
      }

      .Sub_Link {
        width: 118px;
        // width: 17vw !important;
        padding: 12px 0 !important;
        justify-content: center !important;

        span {
          // display: none;
        }
      }
    }

    .live-class-option {
      .option {
        display: flex;
        align-items: center;
        padding: 15px 10px;
        text-decoration: none;
        border-radius: 15px;
        margin: 20px 5px;
        color: #fff;
        height: 50px;
        text-align: center;
        font-size: 12px;
        border: 3px solid #fff;
        box-shadow: 0px 2px 14px -3px #88888859;
        width: 140px;
        justify-content: center;
        span {
          // display: none !important;
        }
      }
    }
  }
}

@media all and (min-width: 320px) and (max-width: 767px) {
  .Slider_Responsive .SubSliderContainer .Slider_Icon_Header {
    margin-right: 5px;
    display: none;
  }
  .Slider_Responsive {
    .SubSliderContainer {
      .Sub_Link {
        padding: 12px 8px !important;
        width: 118px !important;
      }
    }
  }
  .Slider_Responsive {
    .live-class-option {
      .option {
        display: flex;
        align-items: center;
        padding: 15px 10px;
        text-decoration: none;
        border-radius: 15px;
        margin: 20px 5px;
        color: #fff;
        height: 50px;
        text-align: center;
        font-size: 12px;
        border: 3px solid #fff;
        box-shadow: 0px 2px 14px -3px #88888859;
        width: 118px;
        justify-content: center;
        i {
          display: none !important;
        }
      }
    }
  }
}

.custom-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999999;
  background-color: #85daeecc;
  // background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-share-wrapper {
  display: flex;
  gap: 0px 3px;
  justify-content: center;
}



.popup_inner {
  position: absolute;
  // width: 50%;
  // height: auto;
  // width: -moz-fit-content;
  margin: auto;
  background: rgb(255, 255, 255);
  border-radius: 30px;
  padding: 30px 35px;
  display: block;
}

.Popup_Close_Btn {
  position: absolute;
  right: 25px;
  top: 15px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.popup_Title {
  position: initial;
}

// .popup_Title {
//     font-size: 25px;
//     font-weight: bold;
//     margin-right: 30px;
//     // color: #000;
// }
.Popup_Input_Container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0px auto 30px;
  border-bottom: 0px solid #dfe3e6;
  padding-bottom: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .social-share-wrapper {
    flex-wrap: wrap;
  }

}

@media (min-width: 300px) and (max-width: 767px) {
  .Popup_Close_Btn {
    width: 25px !important;
    height: 25px !important;
    right: 10px !important;
  }

  .Demo__some-network {
    button {
      svg {
        width: 28px;
      }
    }
  }

  .popup_Title {
    font-size: 27px;
    margin-bottom: 0px;
  }

  .popup_inner {
    padding: 30px 20px;
    margin: 0 10px;
    margin-top: 40px !important;
    height: auto !important;
  }

  .Ex_Card_Container .Available_Card_Outer_Container .Available_Card_Main_Container .Card_Main_Inner_Container .Card_Cont {
    padding: 20px 10px 20px 10px;
  }
}

@media (min-width: 600px) and (max-width: 1020px) {
  .popup_inner {
    // width: 80%;
  }

  .Popup_Close_Btn {
    width: 20px;
    height: 20px;
  }

  // .popup_Title {
  //     font-size: 25px;
  //     font-weight: bold;
  //     // color: #5c5c5c;
  // }
  .Popup_Input_Container {
    margin: 15px 0px;
    padding-bottom: 15px;
  }
}

@media (min-width: 250px) and (max-width: 600px) {
  .popup_inner {
    // width: 90%;
  }

  .Popup_Close_Btn {
    width: 15px;
    height: 15px;
  }

  // .popup_Title {
  //     font-size: 20px;
  //     font-weight: bold;
  //     // color: #5c5c5c;
  // }
  .Popup_Input_Container {
    margin: 15px 0px;
    padding-bottom: 15px;
  }
}

@media (min-width: 250px) and (max-width: 600px) {
  .custom-popup .popup_inner {
    .popup_Title {
      margin-bottom: 0px;
      font-size: 34px;
    }
  }
}